import React, { ChangeEventHandler, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Select } from "@xometry/xometry_loft";
import {
  GetUsersOrganizationsByIdentityQuery,
  GetUsersOrganizationsByIdentityQueryResult,
} from "../../schema/generated/schema";
import { origins } from "../../utils/constants";
import { ContextSwitcherWrapper } from "./ContextSwitcher.styled";

const PERSONAL_DASHBOARD_VALUE = "personal-dashboard";

interface Props {
  loggedInUserId: string;
  setUsersOrgs: (
    orgs: GetUsersOrganizationsByIdentityQueryResult["getUsersOrganizationsByIdentity"]
  ) => void;
}

const ContextSwitcher = ({ loggedInUserId, setUsersOrgs }: Props) => {
  const {
    data: { getUsersOrganizationsByIdentity: orgs = [] } = {},
  } = useQuery<GetUsersOrganizationsByIdentityQueryResult>(
    GetUsersOrganizationsByIdentityQuery,
    {
      variables: {
        application: "xometry",
        userId: loggedInUserId,
      },
    }
  );

  useEffect(() => {
    if (orgs.length) {
      setUsersOrgs([...orgs]);
    }
  }, [orgs]);

  // refId === teamId
  const selectChangeHandler: ChangeEventHandler<HTMLSelectElement> = e => {
    if (e.target.value !== PERSONAL_DASHBOARD_VALUE) {
      const teamId = encodeURIComponent(e.target.value);
      window.location.assign(`${origins.home}/quoting/org/${teamId}`);
    }
  };

  return (
    <>
      {orgs && orgs?.length > 0 ? (
        <ContextSwitcherWrapper>
          <Select scale="large" width={312} onChange={selectChangeHandler}>
            <option value={PERSONAL_DASHBOARD_VALUE}>Personal Dashboard</option>
            {orgs?.map(org => {
              return org ? (
                <option key={org.refId} value={org.refId}>
                  {org.name}
                </option>
              ) : (
                <></>
              );
            })}
          </Select>
        </ContextSwitcherWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default ContextSwitcher;
