import styled from "@emotion/styled";
import { Box, Text, theme, Flex } from "@xometry/xometry_loft";

export const CarbonOffsetContainer = styled(Box)`
  border-radius: 3px 3px 0 0;
  box-shadow: 0px 1px 4px 0px #C1CAD1;
  position: relative;
  box-sizing: border-box;
  height: max-content;
  margin-bottom: 4px;
  background-color: ${theme.colors.white};
  h3 {
    font-weight: ${theme.fontWeights.semibold};
    margin: ${theme.space[0]};
    margin-bottom: ${theme.space[1]};
    font-size: ${theme.fontSizes[2]};
  }
`;

export const Tooltip = styled.div`
  margin: 2px 6px;
  position: absolute;
  top: 12px;
  right: 12px;
  .tooltip.show {
    opacity: 1;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 8px;
  background: ${theme.colors.greens[5]};
  border-radius: 0 0 3px 3px;
`;

export const GoGreenLogo = styled.img`
  width: 71px;
  height: 27px;
  margin-right: 16px;
  align-self: center;
`;

export const InfoIcon = styled.i`
  cursor: pointer;
  color: ${theme.colors.greens[125]};
`;

export const CalculationHeader = styled(Flex)`
  padding: 8px;
  border-radius: 4px;
  align-items: baseline;
`;

export const CalculationText = styled(Text)`
  font-weight: ${theme.fontWeights.semibold};
  font-size: 28px !important;
  margin-right: 8px;
  color: ${theme.colors.darkBlues[90]};
`;

export const UserCalculationText = styled(CalculationText)`
  color: ${theme.colors.greens[125]};
`;

export const OffsetText = styled.span`
  color: ${theme.colors.darkBlues[100]};
`;

export const CarbonOffset = styled(Flex)`
  padding: ${theme.space[3]};
  justify-content: space-between;
`;

export const CarbonOffsetHeader = styled(Flex)`
  flex-direction: column;
`;

export const ContributionContainer = styled(Flex)`
  flex-direction: column;
  margin: 8px 12px;

  > span {
    color: ${theme.colors.darkBlues[50]};
  }

  span {
    font-size: ${theme.fontSizes[1]};
  }
`;

export const OffsetBottomText = styled(Text)`
  color: ${theme.colors.darkBlues[75]};
`;
