import styled from "@emotion/styled";
import { Flex, theme } from "@xometry/xometry_loft";

export const GravityToolTip = styled(Flex)`
  max-width: 443px;
  flex-direction: column;
  background: ${theme.colors.white};
  opacity: 1;
  @media (max-width: ${theme.breakpoints[1]}) {
    max-width: 380px;
  }
`;

export const Header = styled(Flex)`
  justify-content: space-between;
  padding: 13px 24px;

  span {
    font-weight: ${theme.fontWeights.semibold};
    font-size: ${theme.fontSizes[2]}};
  }
`;

export const GravityMethodologyImg = styled.img`
  width: 443px;
  @media (max-width: ${theme.breakpoints[1]}) {
    max-width: 380px;
  }
`;

export const MethodologyContainer = styled(Flex)`
  flex-direction: column;
  padding: 13px 24px;
`;

export const MethodologyTitle = styled.span`
  font-size: ${theme.fontSizes[2]};
`;

export const MethodologyText = styled.span`
  margin-top: 8px;
  font-size: ${theme.fontSizes[1]};

  a {
    text-decoration: none;
  }
`;

export const GravityWordmark = styled(Flex)`
  margin-top: 16px;
  font-size: 15px;
  color: ${theme.colors.grays[3]};
`;

export const GravityText = styled.span`
  margin-left: 6px;
  color: ${theme.colors.black};
`;

export const GravityLogo = styled.img`
  margin-left: 8px;
  width: 20px;
  height: 16px;
  align-self: center;
`;
