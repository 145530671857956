/* eslint-disable */
import React from "react";
import ReactTooltip from "react-tooltip";
import { useMediaQuery } from "react-responsive";
import { Flex, Text } from "@xometry/xometry_loft";
import { CarbonOffsetResponse } from "../../schema/generated/schema";
import * as S from "./CarbonOffset.style";
import XomLogoGoGreen from "./Xom-GoGreen-Logo.png";
import { GravityToolTip } from "./GravityToolTip";
import { KILOGRAMS_PER_POUND } from "../../utils/constants";
import { smDesktopBreakpoint } from "../../utils/themeOverrides";


export default function CarbonOffset({
  userCarbonOffset,
  dotNeutralOffset,
}: {
  userCarbonOffset?: CarbonOffsetResponse;
  dotNeutralOffset: number;
}) {
  // TODO: it's preferred to display this information in kg, so we'll want
  // to do this translation on the backend instead
  const totalWorldCo2Pounds = userCarbonOffset?.totalWorldCo2Pounds;
  const totalUserCo2Pounds = userCarbonOffset?.totalUserCo2Pounds;
  if (
    !totalWorldCo2Pounds ||
    totalUserCo2Pounds === undefined ||
    dotNeutralOffset === 0
  ) { return null;}

  function displayOffsetKilograms(lbsOffset: number, maximumFractionDigits: number = 0) {
    const kgOffset = lbsOffset * KILOGRAMS_PER_POUND;
    const roundedKgOffset = kgOffset < 1000000 ? kgOffset : kgOffset / 1000000;

    // Determine amount suffix
    const suffix = kgOffset >= 1000000 ? "million" : "";

    return `${roundedKgOffset.toLocaleString("en-US", {
      maximumFractionDigits,
    })} ${suffix}`
  }

  const renderUserContribution = () => (
    <S.ContributionContainer>
      <Text>Your Contribution</Text>
      <S.CalculationHeader>
        <S.UserCalculationText>
          {displayOffsetKilograms(totalUserCo2Pounds)}{" "}
        </S.UserCalculationText>
        <S.OffsetText>{`kilograms of CO2e Offset`}</S.OffsetText>
      </S.CalculationHeader>
    </S.ContributionContainer>
  );

  const getOffsetDescriptionText = () => {
    return !totalUserCo2Pounds ?
      "The amount of CO2 that Xometry’s network of customers and suppliers has offset." :
      "The amount of CO2 that you and Xometry’s network of customers and suppliers has offset."
  }

  const isBreakpoint = useMediaQuery({
    query: `(max-width: ${smDesktopBreakpoint})`,
  });

  const offset = {
    right: 0,
    top: 0,
  };
  if (isBreakpoint) {
    offset.right = 380;
    offset.top = 300;
  } 

  return (
    <S.CarbonOffsetContainer aria-hidden data-tip data-for="gravity-learn-more">
      <S.CarbonOffset>
        <S.CarbonOffsetHeader>
          {totalUserCo2Pounds ? 
            renderUserContribution()
            : null  
          }
          <S.ContributionContainer>
            <Text>The Xometry Network</Text>
            <S.CalculationHeader>
              <S.CalculationText>
                {displayOffsetKilograms(totalWorldCo2Pounds + dotNeutralOffset, 1)}{" "}
              </S.CalculationText>
              <S.OffsetText>{"kilograms of CO2e"}</S.OffsetText>
            </S.CalculationHeader>
          </S.ContributionContainer>
        </S.CarbonOffsetHeader>
        <S.Tooltip>
          <S.InfoIcon className="fas fa-circle-info" />
          <ReactTooltip
            offset={offset}
            event="click"
            globalEventOff="click"
            className="tooltip"
            padding="0"
            clickable
            type="light"
            place="left"
            effect="solid"
            id="gravity-learn-more"
            scrollHide
            resizeHide
            multiline
            bodyMode
            arrowColor="transparent"
          >
            <GravityToolTip />
          </ReactTooltip>
        </S.Tooltip>
      </S.CarbonOffset>
      <S.DescriptionContainer>
          <Flex>
            {!totalUserCo2Pounds ? (
              <S.GoGreenLogo
                src={XomLogoGoGreen}
                alt="Xometry - Go Green Initiative"
              />
            ) : null}
            <S.OffsetBottomText kind="body-sm">{getOffsetDescriptionText()}</S.OffsetBottomText>
          </Flex>
      </S.DescriptionContainer>
    </S.CarbonOffsetContainer>
  );
}
