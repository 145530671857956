import React, { FC } from "react";
import { Text } from "@xometry/xometry_loft";
import GravityVectorLogo from "./Gravity-Vector.png";
import GravityMethodology from "./gravity-methodology.png";
import * as S from "./GravityToolTip.styles";

export const GravityToolTip: FC = () => {
  const learnMoreLink = "https://www.xometry.com/resources/blog/gravity-climate/";

  return (
    <S.GravityToolTip>
      <S.Header>
        <Text>Embodied Carbon Calculations</Text>
      </S.Header>
      <S.GravityMethodologyImg src={GravityMethodology} />
      <S.MethodologyContainer>
        <S.MethodologyTitle>Methodology</S.MethodologyTitle>
        <S.MethodologyText>
          Cradle-to-gate GHG emissions are calculated using the latest industry
          embodied carbon standards, and from supplier-specific information
          where available.
          <a href={learnMoreLink} target="_blank" rel="noreferrer">
            {" "}
            Learn More
          </a>
        </S.MethodologyText>
        <S.GravityWordmark>
          Powered by
          <S.GravityLogo src={GravityVectorLogo} alt="gravity-vector-logo" />
          <S.GravityText>Gravity</S.GravityText>
        </S.GravityWordmark>
      </S.MethodologyContainer>
    </S.GravityToolTip>
  );
};
