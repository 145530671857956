/* eslint-disable react/no-array-index-key */
import { TabView } from "@xometry/xometry_loft";
import React, {
  ComponentProps,
  FC,
  ReactNode,
  useEffect,
  useState,
} from "react";

type Props = {
  scale?: ComponentProps<typeof TabView>["scale"];
  kind?: ComponentProps<typeof TabView>["kind"];
  tabs: {
    header: ReactNode;
    content: ReactNode;
  }[];
  activeTab?: number;
  setActiveTabInParent?: (tab: number) => void;
  preloadedTabs?: number[];
};

// Lazy Load Tabs, but only load them once. So if you have several tabs that all
// are expensive to initialize... You initialize them once when you first
// navigate to those tabs - and thereafter they are persistent.
export const LazyTabView: FC<Props> = (props) => {
  const { tabs, scale, kind, activeTab, setActiveTabInParent, preloadedTabs } =
    props;

  const [selectedTab, setSelectedTab] = useState(0);
  const [loadedTabs, setLoadedTabs] = useState<number[]>(preloadedTabs || [0]);

  const changeTab = (tab: number) => {
    setLoadedTabs((prev) => prev.filter((i) => i !== tab).concat([tab]));
    setSelectedTab(tab);
    if (setActiveTabInParent) {
      setActiveTabInParent(tab);
    }
  };

  useEffect(() => {
    if (activeTab) {
      if (activeTab >= 0 && activeTab < tabs.length) {
        changeTab(Number(activeTab));
      } else {
        console.error(
          `Invalid activeTab value: ${activeTab}. Setting activeTab to the first tab.`
        );
        changeTab(0); // Set to the first tab if the index is invalid
      }
    }
  }, [activeTab, tabs.length]);

  return (
    <TabView
      selectedTab={selectedTab}
      setSelectedTabInParent={changeTab}
      noConditionalRendering
      scale={scale}
      kind={kind}
    >
      <TabView.Header>
        {tabs.map((t, idx) => (
          <TabView.Header.Tab key={idx}>{t.header}</TabView.Header.Tab>
        ))}
      </TabView.Header>
      <TabView.Content>
        {tabs.map(
          (t, idx) =>
            loadedTabs.includes(idx) && (
              <TabView.Content.Item key={idx}>{t.content}</TabView.Content.Item>
            )
        )}
      </TabView.Content>
    </TabView>
  );
};
