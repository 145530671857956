import styled from "@emotion/styled";

export const ContextSwitcherWrapper = styled.div`
  margin: 28px auto 16px;
  max-width: 1420px;

  select {
    background-color: white;
    margin-left: 20px;
  }
`;